<template>
  <b-overlay :show="loading2" class="row">
    <div class="col-12 col-lg-10 c g">
      <div class="card card-body">
        <div class="row">
          <div class="col-12 col-lg-3">
            <div class="form-group">
              <h5 for="">خط السير</h5>
              <select
                class="form-control"
                name=""
                v-model="filter.group_id"
                id=""
              >
                <option :value="undefined">الكل</option>
                <template v-for="group in groups">
                  <option :value="group._id" :key="group._id">
                    {{ group.title }}
                  </option>
                </template>
                <option :value="'nothing'">بدون خط سير</option>
              </select>
            </div>
          </div>
          <div class="col-12 col-lg-3">
            <div class="form-group">
              <h5 for="">الصف</h5>
              <select
                class="form-control"
                name=""
                v-model="filter.classname"
                id=""
              >
                <option :value="undefined">الكل</option>
                <template v-for="classname in classes">
                  <option :value="classname" :key="classname">
                    {{ classname }}
                  </option>
                </template>
              </select>
            </div>
          </div>
          <div class="col-12 col-lg-3">
            <div class="form-group">
              <h5 for="">الفصل</h5>
              <select
                class="form-control"
                name=""
                v-model="filter.classroom"
                id=""
              >
                <option :value="undefined">الكل</option>
                <template v-for="classroom in classrooms">
                  <option :value="classroom" :key="classroom">
                    {{ classroom }}
                  </option>
                </template>
              </select>
            </div>
          </div>
          <div class="col-12 col-lg-3">
            <div class="form-group">
              <h5 for="">الإسم/الهوية/الهاتف</h5>
              <input
                type="text"
                v-model="filter.filter_text"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-12 col-lg-3">
            <div class="form-group">
              <h5 for="">القسم</h5>
              <select
                class="form-control"
                name=""
                v-model="filter.section_id"
                id=""
              >
                <option :value="undefined">الكل</option>
                <template v-for="section in sections">
                  <option :value="section._id" :key="section._id">
                    {{ section.title }}
                  </option>
                </template>
              </select>
            </div>
          </div>
          <div class="col-12 col-lg-3 g text-center">
            <button
              class="btn btn-relief-success"
              ref="searchbtn"
              @click="get()"
            >
              <i class="fa fa-search"></i> بحث
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" v-if="students.length">
      <div class="card">
        <div class="card-header">
          <h4>
            <i class="fa fa-list"></i>
            استعراض الطلاب
            <button class="btn btn-sm btn-relief-info" @click="printTable()">
              <i class="fa fa-print"></i> طباعة
            </button>
            &nbsp;
            <button class="btn btn-sm btn-relief-primary" @click="printTable2()">
              <i class="fa fa-barcode"></i> طباعة باركود
            </button>
            &nbsp;
            <button class="btn btn-sm btn-relief-danger" @click="removeGroup()">
              <i class="fa fa-user-times"></i> ازالة خط السير للطلاب المعروضين ({{ students.length }})
            </button>
            <div class="col-12 g" v-if="selected_students.length > 0">
              <div class="form-group">
                <h5 for="">نقل الطلاب المحددين الى:</h5>
                <select class="form-control" v-model="selected_group">
                  <option
                    :value="group._id"
                    v-for="group in groups"
                    :key="group._id + '0'"
                  >
                    {{ group.title }}
                  </option>
                </select>
              </div>
              <button class="btn btn-success" @click="moveStudents()">
                نقل الطلاب الآن <i class="fa fa-arrow-left"></i>
              </button>
            </div>
          </h4>
        </div>
        <div class="card-body">
          <div class="col-12 table-responsive" id="table">
            <table class="table table-hover table-bordered custom-table">
              <thead>
                <th v-if="!select_students">
                  م
                  <a
                    class="fa fa-check-square"
                    @click="select_students = true"
                  ></a>
                </th>
                <th v-if="select_students">
                  <a href="javascript:;" class="form-check">
                    <label
                      class="form-check-label"
                      @click="selectAllStudents()"
                    >
                      <i class="fa fa-check-square"></i>
                      اختيار الكل
                    </label>
                  </a>
                </th>
                <th>الإسم</th>
                <th>رقم الهوية</th>
                <th class="dnone">الجوال</th>
                <th>الصف</th>
                <th>الفصل</th>
                <th>خط السير</th>
                <th class="dnone">الحالة</th>
                <th class="no">خيارات</th>
              </thead>
              <tbody>
                <tr v-for="(student, index) in students" :key="student._id">
                  <td v-if="!select_students">{{ index + 1 }}</td>
                  <td v-if="select_students">
                    <div class="form-check">
                      <label class="form-check-label">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          v-model="selected_students"
                          :value="student._id"
                        />
                        تحديد
                      </label>
                    </div>
                  </td>
                  <td>{{ student.name }}</td>
                  <td>{{ student.number }}</td>
                  <td class="dnone">{{ student.phone }}</td>
                  <td>{{ student.classname }}</td>
                  <td>{{ student.classroom }}</td>
                  <td v-html="groupName(student.group_id)"></td>
                  <td class="dnone">
                    {{
                      student.type
                        ? student.type
                            .replace("go", "ذهاب")
                            .replace("back", "عودة")
                            .replace("all", "ذهاب وعودة")
                        : ""
                    }}
                  </td>
                  <td class="no">
                    <b-dropdown text="خيارات" variant="relief-primary">
                      <b-dropdown-item
                        @click="
                          $router.push('/students/overview/' + student._id)
                        "
                      >
                        <i class="fa fa-user text-success"></i>
                        تفاصيل الطالب
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="$router.push('/students/edit/' + student._id)"
                      >
                        <i class="fa fa-user-edit text-primary"></i>
                        تعديل الطالب
                      </b-dropdown-item>
                      <b-dropdown-item @click="deleteStudent(student._id)">
                        <i class="fa fa-user-times text-danger"></i>
                        حذف الطالب
                      </b-dropdown-item>
                    </b-dropdown>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { BOverlay, BLink, BDropdown, BDropdownItem } from "bootstrap-vue";
export default {
  components: {
    BLink,
    BOverlay,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      sections: [],
      section_id: null,
      loading: true,
      students: [],
      // students: localStorage.getItem("students")
      //   ? JSON.parse(localStorage.getItem("students"))
      //   : [],
      filter: {},
      groups: localStorage.getItem("groups")
        ? JSON.parse(localStorage.getItem("groups"))
        : [],
      busses: [],
      classes: [],
      classrooms: [],
      f: false,
      // loading2: localStorage.getItem("students") ? false : true,
      loading2: false,
      user: JSON.parse(localStorage.getItem("user")),
      selected_students: [],
      select_students: false,
      selected_group: null,
    };
  },
  created() {
    if (checkPer(this, "students", "view")) {
      var g = this;
      $.post(api + "/admin/groups/list", {
        jwt: g.user.jwt,
      }).then(function (r) {
        g.groups = JSON.parse(r).response;
        localStorage.setItem("groups", JSON.stringify(JSON.parse(r).response));
      });
      //g.get();
      $.post(api + "/admin/bus/list", {
        jwt: g.user.jwt,
      }).then(function (r) {
        g.busses = JSON.parse(r).response;
      });
      $.post(api + "/admin/sections/list", {
        jwt: g.user.jwt,
      }).then(function (r) {
        g.sections = JSON.parse(r).response;
      });
    }
  },
  methods: {
    groupName(id) {
      var title = `<span class='badge bg-danger'>لا يوجد</span>`;
      this.groups.forEach(function (e) {
        if (e._id == id) {
          title = e.title;
        }
      });
      return title;
    },
    deleteStudent(id) {
      var g = this;
      if (
        confirm("متأكد من حذف الطالب؟") &&
        checkPer(this, "students", "delete")
      ) {
        $.post(api + "/admin/students/delete", {
          jwt: g.user.jwt,
          id: id,
        }).then(function (r) {
          alert("تم الحذف");
          g.get();
        });
      }
    },
    printTable() {
      var divToPrint = document.getElementById("table");
      var newWin = window.open("");
      newWin.document.write(
        `<style>*{direction:rtl} .no{display:none;}table, td, th {
        border: 1px solid;
      }

      table {
        width: 100%;
        border-collapse: collapse;
      }</style>` + divToPrint.innerHTML
      );
      newWin.print();
      newWin.close();
    },
    printTable2(){
      var html = `
      <html dir=rtl>
        <head>
          <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.rtl.min.css">
          <style>*{direction: rtl}</style>
        </head>
        <body>
           <div class="container-fluid">
        <div class="row">

        
      `;
      $("#table tr").each(function(){
        html = html + `
         <div class="card" style="height: 14.2857143vh; width: 50%; padding: 0px; overflow: hidden
            ">
            <div class="card-body text-right" style="border: 1px solid #fafafa; border-width: 4px;">
                <small style='white-space: nowrap;'>
                   <Strong> ${$(this).find('td:nth-child(2)').text()}</Strong>
                    <br>
                    ${$(this).find('td:nth-child(7)').text()}
                    <br>
                    <div class='row'>
                      <div class='col-7'><bR>
                      <span style='font-size: 18px'>مدارس العبير الاهلية</span>
                    </div>
                      <div class='col-5' style='padding-top: 5px'>
                      <img src='https://barcode.tec-it.com/barcode.ashx?data=${$(this).find('td:nth-child(3)').text()?.trim()}&code=Code128&translate-esc=on' style='width: 100%'></div>
                    </div>
                </small>
            </div>
        </div>
        `
      })
      html = html + `</div></div>
        </body>
      </html>`
       var newWin = window.open("");
      newWin.document.write(html)

    },
    removeGroup(){
      var t = prompt(`متأكد من ازالة خط السير ل ${this.students.length} طالب؟\nاكتب: 932434 للتأكيد.`, "")
      if(t == "932434"){
      var g = this;
      $.post(api + "/admin/students/remove-group", {
        jwt: g.user.jwt,
        students: JSON.stringify(this.students?.map(function(x){return x._id}))
      })
        .then(function () {
          g.get()
          alert("تم بنجاح");
        })
        .catch(function () {
          alert("حدث خطأ");
        });
      }
    },
    get() {
      var g = this;
      g.loading = true;
      setTimeout(() => {
        var t = g.$refs.searchbtn.innerHTML;
        g.$refs.searchbtn.innerHTML = `<div class="spinner-border text-white" role="status"></div>`;
        $.post(api + "/admin/students/list", {
          jwt: g.user.jwt,
          filter: JSON.stringify(g.filter),
        }).then(function (r) {
          g.students = JSON.parse(r).response;
          localStorage.setItem(
            "students",
            JSON.stringify(JSON.parse(r).response)
          );
          if (!g.f) {
            g.f = true;
            g.students.forEach((element) => {
              if (!g.classes.includes(element.classname)) {
                g.classes.push(element.classname);
              }
              if (!g.classrooms.includes(element.classroom)) {
                g.classrooms.push(element.classroom);
              }
            });
          }
          g.loading = false;
          g.loading2 = false;
          g.$refs.searchbtn.innerHTML = t;
        });
      }, 1);
    },
    selectAllStudents() {
      var g = this;
      if (g.selected_students.length == 0) {
        g.selected_students = g.students.map((x) => {
          return x._id;
        });
      } else {
        g.selected_students = [];
      }
    },
    moveStudents() {
      var g = this;
      if (confirm("متأكد من نقل الطلاب المحديين؟")) {
        $.post(api + "/admin/students/move-to-group", {
          jwt: this.user.jwt,
          students: JSON.stringify(this.selected_students),
          group_id: this.selected_group,
        })
          .then(function () {
            g.get();
            g.selected_students = [];
            g.selected_group = null;
            g.select_students = false;
            alert("تم بنجاح");
          })
          .catch(function () {
            alert("حدث خطأ");
          });
      }
    },
  },
};
</script>